import { Dropdown, Image, Menu, Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useHovered from 'common/hooks/useHovered';
import UserAvatar from '@components/UserAvatar';
import { signOut } from '@modules/auth/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { SettingType } from '@modules/settings/models';
import Logo from 'common/images/logo.png';

const AboutMenu = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/golovna/asocziacziya">Асоціація</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/montazhnykam">Монтажникам</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="/dlya-spozhyvachiv">Для споживачів</Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to="/golovna">Про нас</Link>
    </Menu.Item>
  </Menu>
);

const WorkMenu = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/vartist-robit">Вартість робіт</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/instrumenty">Інструменти</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="/roboty">Роботи</Link>
    </Menu.Item>
  </Menu>
);

const PartnersMenu = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/komu-doviryayemo">Кому довіряємо</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/kogo-obslugovuyemo">Кого обслуговуємо</Link>
    </Menu.Item>
  </Menu>
);

const Header = () => {
  const history = useHistory();

  const [ref, isHovered]: any = useHovered();
  const dispatch = useDispatch();
  const role = useSelector((state: any) => state.auth.role);
  const name = useSelector((state: any) => state.auth.name);

  const [width, setWidth] = React.useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Header.Wrapper>
      {width > 768 && (
        <Link to="/">
          <Image
            src={Logo}
            preview={false}
            height="50px"
            style={{ paddingLeft: '10px' }}
          />
        </Link>
      )}
      <Header.NavMenu mode="horizontal">
        <Menu.Item key="1">
          <Dropdown overlay={AboutMenu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Про нас <DownOutlined />
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="2">
          <Dropdown overlay={WorkMenu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Роботи <DownOutlined />
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/workers">База</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/posts">Статті</Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Dropdown overlay={PartnersMenu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Наші партнери <DownOutlined />
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/kontakty">Контакти</Link>
        </Menu.Item>
      </Header.NavMenu>

      {width > 768 ? (
        <h3 style={{ paddingTop: '10px' }}>
          БЕЗКОШТОВНА ГАРЯЧА ЛІНІЯ <a href="tel:0800313750">0 800 313 750</a>
        </h3>
      ) : (
        <Link to="/">
          <Image src={Logo} preview={false} height="50px" />
        </Link>
      )}

      <Header.UserMenu ref={ref} isHovered={isHovered}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => history.push(`/settings/${SettingType.User}/`)}
              >
                Мій кабінет
              </Menu.Item>
              {role === 'admin' ? (
                <Menu.Item onClick={() => history.push(`/admin`)}>
                  Адмінка
                </Menu.Item>
              ) : null}

              {role ? (
                <Menu.Item onClick={() => dispatch(signOut())}>Вийти</Menu.Item>
              ) : (
                <Menu.Item onClick={() => history.push(`/admin`)}>
                  Увійти
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Header.DropdownContent>
            {name ? (
              <UserAvatar name={name} />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
            <Header.IconWrapper>
              <DownOutlined />
            </Header.IconWrapper>
          </Header.DropdownContent>
        </Dropdown>
      </Header.UserMenu>
    </Header.Wrapper>
  );
};

Header.Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
`;

Header.Logo = styled(Link)`
  padding-top: 2px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  font-family: 'Gill Sans', cursive;
`;

interface UserMenuProps {
  readonly isHovered: boolean;
}

Header.UserMenu = styled.div<UserMenuProps>`
  position: relative;
  height: 100%;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: ${(props) => (props.isHovered ? '#efefef' : '#fff')};
  }
`;

Header.DropdownContent = styled.div`
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

Header.IconWrapper = styled.span`
  margin-left: 10px;
  color: ${(props) => props.theme.colors.black};
`;

Header.NavMenu = styled(Menu)`
  height: 100%;
  padding-top: 10px;
`;

export default Header;
