import { CreateUpdateWorkerData } from '@modules/worker/models';

const NAMESPACE = 'ADMIN';

export const GET_ADMIN_WORKER = `${NAMESPACE}/GET_ADMIN_WORKER`;
export const GET_ADMIN_WORKERS = `${NAMESPACE}/GET_ADMIN_WORKERS`;
export const UPDATE_ADMIN_WORKER = `${NAMESPACE}/UPDATE_ADMIN_WORKER`;
export const SET_IS_LOADING = `${NAMESPACE}/SET_IS_LOADING`;

export const getAdminWorkers = (payload?: Record<string, any>) => ({
  type: GET_ADMIN_WORKERS,
  payload,
});

export const getAdminWorker = (payload: string) => ({
  type: GET_ADMIN_WORKER,
  payload,
});

export const updateWorker = (payload: {
  id: string;
  data: CreateUpdateWorkerData;
}) => ({
  type: UPDATE_ADMIN_WORKER,
  payload,
});

export const setIsLoading = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
});
