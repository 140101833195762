import * as R from 'ramda';

import {
  GET_ADMIN_WORKER,
  GET_ADMIN_WORKERS,
  SET_IS_LOADING,
  UPDATE_ADMIN_WORKER,
} from './Actions';

export const STATE_KEY = 'admin';

export interface AdminState {
  data: Record<string, any>;
  isLoading: boolean;
}

const initialState: AdminState = {
  data: {},
  isLoading: false,
};

export interface AdminWorkersList {
  _id: Record<string, any>;
  avatar?: string;
  date: Date;
  firstName: Record<string, any>;
  secondName: Record<string, any>;
  thirdName: Record<string, any>;
  workPhoto: ImageInArray[];
  certificates: ImageInArray[];
  about: Record<string, any>;
  owner: Record<string, any>;
  region: Record<string, any>;
  locality: Record<string, any>;
  checkTable: WorkerTalents[];
  status: string;
}

export interface WorkerTalents {
  diagnostics: boolean;
  mounting: boolean;
  service: boolean;
  key: Record<string, any>;
  work: Record<string, any>;
}

export interface ImageInArray {
  file: Record<string, any>;
  name: Record<string, any>;
}

const Reducer = (
  state: AdminState = initialState,
  { type, payload }: any,
): AdminState => {
  switch (type) {
    case `${UPDATE_ADMIN_WORKER}_SUCCESS`:
    case `${GET_ADMIN_WORKER}_SUCCESS`: {
      return R.assocPath(['data', payload?.id], payload, state);
    }
    case `${GET_ADMIN_WORKERS}_SUCCESS`: {
      return R.compose(
        R.assoc('data', R.indexBy(R.prop('_id'), payload.data)),
        R.assoc('total', payload.total_count),
      )(state);
    }
    case SET_IS_LOADING: {
      return R.assoc('isLoading', payload, state);
    }
    default: {
      return state;
    }
  }
};

export const getAdminWorkers = R.compose(R.values, R.path([STATE_KEY, 'data']));

export const getTotalAdminWorkersCount = R.path([STATE_KEY, 'total']);

export const getAdminListWorkers = R.compose(
  R.map((worker: AdminWorkersList) => ({
    _id: worker._id,
    avatar: worker.avatar,
    date: worker.date,
    firstName: worker.firstName,
    secondName: worker.secondName,
    thirdName: worker.thirdName,
    workPhoto: worker.workPhoto,
    certificates: worker.certificates,
    about: worker.about,
    owner: worker.owner,
    region: worker.region,
    locality: worker.locality,
    checkTable: worker.checkTable,
    status: worker.status,
  })),
  getAdminWorkers,
);

export const getAdminWorker = (state: AdminWorkersList, id: string) =>
  R.path([STATE_KEY, 'data', id], state);

export const getAdminWorkerDetails = (state: AdminWorkersList, id: string) =>
  R.compose(
    (event: any) => ({
      ...event,
    }),
    R.defaultTo({}),
  )(getAdminWorker(state, id));

export const getIsLoading = R.path([STATE_KEY, 'isLoading']);

export const getIsAdmin = R.path(['auth', 'role']);

export default Reducer;
