import axios from 'axios';

import AppConfig from '@config/AppConfig';
import { getUrlWithParams } from '@common/helpers/api-helpers';
import { CreateUpdateWorkerData } from '@modules/worker/models';

const URL = `${AppConfig.apiUrl}/workers`;

export const getWorker = (id: Record<string, any>, ...config: any) =>
  axios.get(`${URL}/${id}`, ...config);

export const getComments = (id: Record<string, any>, ...config: any) =>
  axios.get(`${AppConfig.apiUrl}/comments/${id}`, ...config);

export const getWorkers = (params: Record<string, any>, ...config: any) =>
  axios.get(getUrlWithParams(`${URL}/`, params), ...config);

export const createWorker = (data: CreateUpdateWorkerData, ...config: any) =>
  axios.post(`${URL}/`, data, ...config);

export const createComment = (data: any, ...config: any) =>
  axios.post(`${AppConfig.apiUrl}/comments`, data, ...config);

export const updateWorker = (
  { id, data }: { id: string; data: CreateUpdateWorkerData },
  ...config: any
) => axios.put(`${URL}/${id}`, data, ...config);
