import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  console.log = function () {}; //eslint-disable-line
}

ReactDOM.render(<App />, document.getElementById('root'));
