import { all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import workerSagas from '@modules/worker/Sagas';
import authSagas from '@modules/auth/Sagas';
import adminSagas from '@modules/admin/Sagas';
import userSagas from '@modules/user/Sagas';

function* rootSaga(): SagaIterator {
  yield all([
    fork(workerSagas),
    fork(authSagas),
    fork(adminSagas),
    fork(userSagas),
  ]);
}

export default rootSaga;
