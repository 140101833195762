import axios from 'axios';

import AppConfig from '@config/AppConfig';
import { getUrlWithParams } from '@common/helpers/api-helpers';
import { CreateUpdateWorkerData } from '@modules/worker/models';

const URL = `${AppConfig.apiUrl}/admin`;

export const getAdminWorker = (id: string, ...config: any) =>
  axios.get(`${URL}/worker/${id}`, ...config);

export const getAdminWorkers = (params: Record<string, any>, ...config: any) =>
  axios.get(getUrlWithParams(`${URL}/`, params), ...config);

export const updateWorker = (
  { id, data }: { id: string; data: CreateUpdateWorkerData },
  ...config: any
) => axios.put(`${URL}/worker/${id}`, data, ...config);
