import * as R from 'ramda';

import {
  GET_WORKER,
  GET_WORKERS,
  CREATE_WORKER,
  UPDATE_WORKER,
  SET_IS_LOADING,
  CREATE_COMMENT,
  GET_COMMENTS,
} from './Action';
import { CreateUpdateWorkerData } from '@modules/worker/models';

export const STATE_KEY = 'worker';

export interface WorkerState {
  data: Record<string, any>;
  isLoading: boolean;
}

const initialState: WorkerState = {
  data: {},
  isLoading: false,
};

const Reducer = (
  state: WorkerState = initialState,
  { type, payload }: any,
): WorkerState => {
  switch (type) {
    case `${GET_WORKER}_SUCCESS`:
    case `${CREATE_WORKER}_SUCCESS`:
    case `${UPDATE_WORKER}_SUCCESS`: {
      return R.assocPath(['data', payload?._id], payload, state);
    }
    case `${GET_WORKERS}_SUCCESS`: {
      return R.compose(
        R.assoc('data', R.indexBy(R.prop('_id'), payload.data)),
        R.assoc('total', payload.total_count),
      )(state);
    }
    case `${CREATE_COMMENT}_SUCCESS`: {
      return R.assocPath(['comments', payload], payload, state);
    }
    case `${GET_COMMENTS}_SUCCESS`: {
      return R.assoc('comments', payload.data, state);
    }
    case SET_IS_LOADING: {
      return R.assoc('isLoading', payload, state);
    }
    default: {
      return state;
    }
  }
};

export const getWorkers = R.compose(R.values, R.path([STATE_KEY, 'data']));

export const getTotalWorkersCount = R.path([STATE_KEY, 'total']);

export const getListWorkers = R.compose(
  R.map((worker: CreateUpdateWorkerData) => ({
    _id: worker._id,
    avatar: worker.avatar,
    date: worker.date,
    firstName: worker.firstName,
    secondName: worker.secondName,
    thirdName: worker.thirdName,
    photos: worker.photos,
    certificates: worker.certificates,
    about: worker.about,
    activity: worker.activity,
    quality: worker.quality,
    comfort: worker.comfort,
    materials: worker.materials,
    equipment: worker.equipment,
    experience: worker.experience,
    owner: worker.owner,
    city_locality: worker.city_locality,
    locality: worker.locality,
    professional: worker.professional,
    status: worker.status,
    rating: worker.rating,
    education: worker.education,
  })),
  getWorkers,
);

export const getWorkerDetails = (state: CreateUpdateWorkerData, id: string) =>
  R.path([STATE_KEY, 'data', id], state);

export const getIsLoading = R.path([STATE_KEY, 'isLoading']);

export default Reducer;
