import { CreateUpdateWorkerData } from '@modules/worker/models';

const NAMESPACE = 'WORKER';

export const GET_WORKER = `${NAMESPACE}/GET_WORKER`;
export const GET_WORKERS = `${NAMESPACE}/GET_WORKERS`;
export const GET_COMMENTS = `${NAMESPACE}/GET_COMMENTS`;
export const UPDATE_WORKER = `${NAMESPACE}/UPDATE_WORKER`;
export const CREATE_WORKER = `${NAMESPACE}/CREATE_WORKER`;
export const SET_IS_LOADING = `${NAMESPACE}/SET_IS_LOADING`;
export const CREATE_COMMENT = `${NAMESPACE}/CREATE_COMMENT`;

export const createComment = (payload: any) => ({
  type: CREATE_COMMENT,
  payload,
});

export const getComments = (payload: string) => ({
  type: GET_COMMENTS,
  payload,
});

export const getWorker = (payload: string) => ({
  type: GET_WORKER,
  payload,
});

export const getWorkers = (payload?: string) => ({
  type: GET_WORKERS,
  payload,
});

export const setIsLoading = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
});

export const createWorker = (payload: { data: CreateUpdateWorkerData }) => ({
  type: CREATE_WORKER,
  payload,
});

export const updateWorker = (payload: {
  id: string;
  data: CreateUpdateWorkerData;
}) => ({
  type: UPDATE_WORKER,
  payload,
});
