import { message as notify } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { Action } from '@store/models';
import { getReduxAction } from '@store/helpers';

import * as ApiService from './services/api';
import {
  GET_ADMIN_WORKERS,
  GET_ADMIN_WORKER,
  UPDATE_ADMIN_WORKER,
  setIsLoading,
} from './Actions';

function* getAdminWorkerSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.getAdminWorker, payload);
    yield put(setIsLoading(false));

    yield put(getReduxAction(type, data));
  } catch (err) {
    yield put(getReduxAction(type));
  }
}

function* getAdminWorkersSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.getAdminWorkers, payload);
    yield put(setIsLoading(false));

    yield put(getReduxAction(type, data));
  } catch (err) {
    yield put(getReduxAction(type));
  }
}

function* updateWorkerSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.updateWorker, payload);
    yield put(setIsLoading(false));

    yield put(getReduxAction(type, data));
    yield call(notify.success, `${data._id} was successfully updated`);
  } catch (err) {
    yield put(getReduxAction(type));
  }
}

function* workerSagas(): SagaIterator {
  yield all([
    yield takeEvery(GET_ADMIN_WORKER, getAdminWorkerSaga),
    yield takeEvery(GET_ADMIN_WORKERS, getAdminWorkersSaga),
    yield takeEvery(UPDATE_ADMIN_WORKER, updateWorkerSaga),
  ]);
}

export default workerSagas;
