import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory, RouteProps } from 'react-router-dom';

import { getIsAdmin } from '../Reducer';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const history = useHistory();
  const role = useSelector(getIsAdmin);

  useEffect(() => {
    if (role !== 'admin') {
      history.push('/signin');
      return;
    }
  }, [history, role]);

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (role === 'admin') {
          return <Component {...props} />;
        }

        return <Redirect to="/signin" />;
      }}
    />
  );
};

export default PrivateRoute;
