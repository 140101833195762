import { message as notify } from 'antd';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { push } from 'connected-react-router';

import { Action } from '@store/models';
import { getReduxAction } from '@store/helpers';

import * as ApiService from './services/api';
import {
  GET_WORKER,
  GET_WORKERS,
  GET_COMMENTS,
  setIsLoading,
  CREATE_WORKER,
  UPDATE_WORKER,
  CREATE_COMMENT,
} from './Action';
import { addWorker } from '@modules/auth/Actions';

function* getWorkerSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.getWorker, payload);
    yield put(setIsLoading(false));

    yield put(getReduxAction(type, data));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(getReduxAction(type));
  }
}

function* getCommentsSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.getComments, payload);
    yield put(setIsLoading(false));
    yield put(getReduxAction(type, data));
  } catch (error) {
    yield put(setIsLoading(false));
    yield put(getReduxAction(type));
  }
}

function* getWorkersSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.getWorkers, payload);
    yield put(setIsLoading(false));

    yield put(getReduxAction(type, data));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(getReduxAction(type));
  }
}

function* createWorkerSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.createWorker, payload);
    yield put(setIsLoading(false));
    console.log(data);
    yield put(getReduxAction(type, data));
    yield fork(notify.success, `Анкету ${data.CardId} створено успішно`);
    yield put(push(`/worker/${data.CardId}`));
    yield put(addWorker(data.CardId));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(getReduxAction(type));
  }
}

function* createCommentSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { comments } = yield call(ApiService.createComment, payload);
    yield put(setIsLoading(false));
    console.log(comments);
    yield put(getReduxAction(type, comments));
  } catch (error) {
    yield put(setIsLoading(false));
  }
}

function* updateWorkerSaga({ type, payload }: Action): SagaIterator {
  try {
    yield put(setIsLoading(true));
    const { data } = yield call(ApiService.updateWorker, payload);
    yield put(setIsLoading(false));
    console.log(data);
    yield put(getReduxAction(type, data));
    yield call(notify.success, `Анкета ${data._id} була успішно оновлена`);
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(getReduxAction(type));
  }
}

function* workerSagas(): SagaIterator {
  yield all([
    yield takeEvery(GET_WORKER, getWorkerSaga),
    yield takeEvery(GET_WORKERS, getWorkersSaga),
    yield takeEvery(GET_COMMENTS, getCommentsSaga),
    yield takeEvery(CREATE_WORKER, createWorkerSaga),
    yield takeEvery(UPDATE_WORKER, updateWorkerSaga),
    yield takeEvery(CREATE_COMMENT, createCommentSaga),
  ]);
}

export default workerSagas;
