import { lazy } from 'react';
import {
  UserOutlined,
  ContainerOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

export enum SettingType {
  User = 'user',
  Form = 'form',
  Report = 'report',
  Generate = 'generate',
}

export const MenuItems = [
  {
    key: SettingType.User,
    title: 'Налаштування користувача',
    icon: UserOutlined,
  },
  {
    key: SettingType.Form,
    title: 'Налаштування анкети',
    icon: ContainerOutlined,
  },
  {
    key: SettingType.Report,
    title: 'Генерувати звіт',
    icon: SolutionOutlined,
  },
];

export const MenuContents = [
  {
    component: lazy(() => import('@modules/user/forms/Update')),
    key: SettingType.User,
    title: 'Ваші особисті дані',
  },
  {
    component: lazy(() => import('@modules/worker/containers/Manage')),
    key: SettingType.Form,
    title: 'Ваша анкета',
  },
  {
    component: lazy(() => import('@modules/worker/containers/Report')),
    key: SettingType.Report,
    title: 'Налаштування звіту',
  },
];
