import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import { getIsAuthenticated } from '@modules/auth/Reducer';
import PrivateRoute from '@modules/auth/components/PrivateRoute';
import AdminRoute from '@modules/admin/components/AdminRoute';

//Static app pages
const HomePage = lazy(() => import('@modules/app-pages/HomePage/HomePage'));
const AssociationPage = lazy(
  () => import('@modules/app-pages/AssociationPage/AssociationPage'),
);
const AdjusterPage = lazy(
  () => import('@modules/app-pages/AdjusterPage/AdjusterPage'),
);
const ClientPage = lazy(
  () => import('@modules/app-pages/ClientPage/ClientPage'),
);
const CostPage = lazy(() => import('@modules/app-pages/CostPage/CostPage'));
const InstrumentsPage = lazy(
  () => import('@modules/app-pages/InstrumentsPage/InstrumentsPage'),
);
const WhoWeTrustPage = lazy(
  () => import('@modules/app-pages/WhoWeTrustPage/WhoWeTrustPage'),
);
const WhoWeServe = lazy(
  () => import('@modules/app-pages/WhoWeServe/WhoWeServe'),
);
const AboutPage = lazy(() => import('@modules/app-pages/AboutPage/AboutPage'));
const PostsPage = lazy(() => import('@modules/app-pages/PostsPage/PostPage'));
const ContactsPage = lazy(
  () => import('@modules/app-pages/ContactsPage/ContactsPage'),
);
const WorkPage = lazy(() => import('@modules/app-pages/WorksPage/WorksPage'));
const WorkExamplePage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/WorkExamplesPage'),
);
const SplitSystemPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/SplitSystemPage'),
);
const CabelsPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/Cabels'),
);
const TermoizolationPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/TermoizolationPage'),
);
const InstallationCostPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/InstallationCostPage'),
);
const RefuelingAirPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/RefuelingAirPage'),
);

const InstrumentsPageinPosts = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/InstrumentsPage'),
);
const ImportantTOPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/ImportantTOPage'),
);
const SamsungPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/SamsungPage'),
);
const HisensePage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/HisensePage'),
);
const ElectroluxPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/ElectroluxPage'),
);
const ZanussiPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/ZanussiPage'),
);
const GreePage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/GreePage'),
);
const CooperAndHunterPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/CooperAndHunterPage'),
);
const ACElectricPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/ACElectricPage'),
);
const MideaPage = lazy(
  () => import('@modules/app-pages/PostsPage/Pages/MideaPage'),
);
//Admin pages.
const AdminPanel = lazy(() => import('@modules/admin/containers/Panel'));
const AdminDetail = lazy(() => import('@modules/admin/containers/Details'));
// Auth pages.
const SignIn = lazy(() => import('@modules/auth/containers/SignIn'));
const SignUp = lazy(() => import('@modules/auth/containers/SignUp'));
// Worker containers.
const WorkerList = lazy(() => import('@modules/worker/containers/List'));
const CreateWorker = lazy(() => import('@modules/worker/containers/Create'));
const WorkerDetail = lazy(() => import('@modules/worker/containers/More'));
const WorkerManage = lazy(() => import('@modules/worker/containers/Manage'));
// Settings pages.
const ManageSettings = lazy(
  () => import('@modules/settings/containers/Manage'),
);
// Other containers.
const NotFoundPage = lazy(() => import('@components/NotFound'));

const RootRedirect = () => {
  const isAuth = useSelector(getIsAuthenticated);

  if (!isAuth) {
    return <Redirect to="/signin" />;
  }

  return <Redirect to={`/workers`} />;
};

export default (
  <>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/golovna/asocziacziya" component={AssociationPage} />
      <Route exact path="/montazhnykam" component={AdjusterPage} />
      <Route exact path="/dlya-spozhyvachiv" component={ClientPage} />
      <Route exact path="/vartist-robit" component={CostPage} />
      <Route exact path="/instrumenty" component={InstrumentsPage} />
      <Route exact path="/komu-doviryayemo" component={WhoWeTrustPage} />
      <Route exact path="/kogo-obslugovuyemo" component={WhoWeServe} />
      <Route exact path="/posts" component={PostsPage} />
      <Route exact path="/golovna" component={AboutPage} />
      <Route exact path="/kontakty" component={ContactsPage} />
      <Route exact path="/roboty" component={WorkPage} />
      <Route exact path="/posts/pryklady-robit" component={WorkExamplePage} />
      <Route
        exact
        path="/posts/montazh-split-systemy"
        component={SplitSystemPage}
      />
      <Route exact path="/posts/kabeli" component={CabelsPage} />
      <Route
        exact
        path="/posts/termoizolyacziya"
        component={TermoizolationPage}
      />
      <Route
        exact
        path="/posts/vartist-vsanovlennya-kondyczionera"
        component={InstallationCostPage}
      />
      <Route
        exact
        path="/posts/ustatkuvannya-dlya-zapravky-perevirky-ta-obslugovuvannya-kondyczioneriv"
        component={RefuelingAirPage}
      />
      <Route
        exact
        path="/posts/instrumenty"
        component={InstrumentsPageinPosts}
      />
      <Route exact path="/posts/samsung" component={SamsungPage} />
      <Route exact path="/posts/hisense" component={HisensePage} />
      <Route exact path="/posts/electrolux" component={ElectroluxPage} />
      <Route exact path="/posts/obovyazkove-to" component={ImportantTOPage} />
      <Route exact path="/posts/zanussi" component={ZanussiPage} />
      <Route exact path="/posts/gree" component={GreePage} />
      <Route exact path="/posts/cooperhunter" component={CooperAndHunterPage} />
      <Route exact path="/posts/ac-electric" component={ACElectricPage} />
      <Route exact path="/posts/midea" component={MideaPage} />

      <PrivateRoute exact path="/worker" component={RootRedirect} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/workers" component={WorkerList} />
      <Route exact path="/worker/:id" component={WorkerDetail} />
      <PrivateRoute exact path="/workers/create" component={CreateWorker} />
      <PrivateRoute exact path="/worker/:id/manage" component={WorkerManage} />
      <AdminRoute exact path="/admin" component={AdminPanel} />
      <AdminRoute exact path="/admin/worker/:id" component={AdminDetail} />
      <PrivateRoute
        exact
        path="/settings/:settingsId(user|form|report)"
        component={ManageSettings}
      />
      {/* Catch all routes */}
      <Route component={NotFoundPage} />
    </Switch>
  </>
);
